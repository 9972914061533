import { useLocation, Outlet } from 'react-router-dom';
// @mui
import { Box, Link, Container, Typography, Stack } from '@mui/material';
// components
import Logo from '../../components/Logo';
//
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';
import MainFooter2 from "./MainFooter2";
import MainFooter3 from "./MainFooter3";

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();

  const isHome = pathname === '/';

  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader />

      <Outlet />

      <Box sx={{ flexGrow: 1 }} />

      {/*{!isHome ? (*/}
      {/*  <MainFooter />*/}
      {/*) : (*/}
      {/*  <Box*/}
      {/*    sx={{*/}
      {/*      py: 5,*/}
      {/*      textAlign: 'center',*/}
      {/*      position: 'relative',*/}
      {/*      bgcolor: 'background.default',*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Container>*/}
      {/*      <Logo sx={{ mb: 1, mx: 'auto' }} />*/}

      {/*      <Typography variant="caption" component="p">*/}
      {/*        © All rights reserved*/}
      {/*        <br /> made by &nbsp;*/}
      {/*        <Link href="https://invict.site">Invict</Link>*/}
      {/*      </Typography>*/}
      {/*    </Container>*/}
      {/*  </Box>*/}
      {/*)}*/}
      <MainFooter3/>

            {/*<MainFooter2 />*/}
    </Stack>
  );
}
