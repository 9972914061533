import {useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {styled} from '@mui/material/styles';
import {Grid, Link, Divider, Container, Typography, Stack} from '@mui/material';
// routes
import {PATH_PAGE, PATH_WEB} from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import SocialsButton from '../../components/SocialsButton';



import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// ----------------------------------------------------------------------

const LINKS = [
    {
        headline: 'INTELLIGENCEFOREX',
        children: [
            {name: 'About us', href: PATH_PAGE.about},
            {name: 'Contact us', href: PATH_PAGE.contact},
            {name: 'Blog', href: PATH_WEB.comingSoon},
            {name: 'Pricing', href: PATH_PAGE.pricing},
        ],
    },
    // {
    //     headline: 'Legal',
    //     children: [
    //         {name: 'Terms and Condition', href: '#'},
    //         {name: 'Privacy Policy', href: '#'},
    //     ],
    // },
    {
        headline: 'Contact',
        children: [
            {name: 'info@intelligenceforex.com', href: '#'},
            {name: 'Dar es salaam, University Road', href: '#'},
        ],
    },
];

const RootStyle = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.background.default,
    bottom: 0,
    zIndex: '-1',
    width: '100%',
    height: '90vh',
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
}));


// ----------------------------------------------------------------------

export default function MainFooter2() {

    const [open, setOpen] =useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{height: '100vh'}}>
                <RootStyle>
                    <Divider/>
                    <Container sx={{pt: 10}}>
                        <Grid
                            container
                            justifyContent={{xs: 'center', md: 'space-between'}}
                            sx={{textAlign: {xs: 'center', md: 'left'}}}
                        >
                            <Grid item xs={12} sx={{mb: 3}}>
                                <Logo sx={{mx: {xs: 'auto', md: 'inherit'}}}/>
                            </Grid>
                            <Grid item xs={8} md={3}>
                                {/*<Typography variant="body2" sx={{pr: {md: 5}}}>*/}
                                {/*    <b>RISK DISCLOSURE</b> <br/>*/}
                                {/*    Foreign Exchange and CFD trading are high risk and not*/}
                                {/*    suitable for everyone. You should carefully consider your*/}
                                {/*    investment objectives, level of experience and risk appetite*/}
                                {/*    before making a decision to trade.*/}
                                {/*    Most importantly, do not invest money you cannot afford to lose.*/}
                                {/*</Typography>*/}
                                <Stack
                                    direction="row"
                                    justifyContent={{xs: 'center', md: 'flex-start'}}
                                    sx={{mt: 5, mb: {xs: 5, md: 0}}}
                                >
                                    <Button onClick={()=>console.log("yess")}  >
                                    <b>RISK DISCLOSURE</b>
                                    </Button>
                                </Stack>

                                <Stack
                                    direction="row"
                                    justifyContent={{xs: 'center', md: 'flex-start'}}
                                    sx={{mt: 5, mb: {xs: 5, md: 0}}}
                                >
                                    <SocialsButton sx={{mx: 0.5}}/>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} md={7}>
                                <Stack
                                    spacing={5}
                                    direction={{xs: 'column', md: 'row'}}
                                    justifyContent="space-between"
                                >
                                    {LINKS.map((list) => (
                                        <Stack key={list.headline} spacing={2}>
                                            <Typography component="p" variant="overline">
                                                {list.headline}
                                            </Typography>
                                            {list.children.map((link) => (
                                                <Link
                                                    to={link.href}
                                                    key={link.name}
                                                    color="inherit"
                                                    variant="body2"
                                                    component={RouterLink}
                                                    sx={{display: 'block'}}
                                                >
                                                    {link.name}
                                                </Link>
                                            ))}
                                        </Stack>
                                    ))}
                                </Stack>
                            </Grid>
                        </Grid>

                        <Typography
                            component="p"
                            variant="body2"
                            sx={{
                                mt: 10,
                                pb: 5,
                                fontSize: 13,
                                textAlign: {xs: 'center', md: 'left'},
                            }}
                        >
                            © 2021. All rights reserved
                        </Typography>
                    </Container>
                </RootStyle>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <b>RISK DISCLOSURE</b>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div>
                                <Typography variant="body2" sx={{pr: {md: 5}}}>
                                    Foreign Exchange and CFD trading are high risk and not
                                    suitable for everyone. You should carefully consider your
                                    investment objectives, level of experience and risk appetite
                                    before making a decision to trade.
                                    Most importantly, do not invest money you cannot afford to lose.
                                </Typography>

                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Disagree</Button>
                        <Button onClick={handleClose} autoFocus>
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
        </div>
    );
}
