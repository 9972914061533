import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack } from '@mui/material';
// routes
import {PATH_PAGE, PATH_WEB} from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import SocialsButton from '../../components/SocialsButton';

// ----------------------------------------------------------------------

const LINKS = [
    {
        headline: 'Minimal',
        children: [
            { name: 'About us', href: PATH_PAGE.about },
            { name: 'Contact us', href: PATH_PAGE.contact },
            { name: 'Blog', href: PATH_WEB.comingSoon },
            { name: 'Pricing', href: PATH_PAGE.pricing },
        ],
    },
    // {
    //     headline: 'Legal',
    //     children: [
    //         { name: 'Terms and Condition', href: '#' },
    //         { name: 'Privacy Policy', href: '#' },
    //     ],
    // },
    // {
    //     headline: 'Contact',
    //     children: [
    //         { name: 'info@intelligenceforex.com', href: '#' },
    //         { name: 'Dar es salaam, University Road', href: '#' },
    //     ],
    // },
];

const RootStyle = styled('div')(({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter3() {
    return (
        <RootStyle>
            <Divider />
            <Container sx={{ pt: 5 }}>
                <Grid
                    container
                    justifyContent={{ xs: 'center', md: 'space-between' }}
                    sx={{ textAlign: { xs: 'center', md: 'left' } }}
                >
                    <Grid item xs={12} sx={{ mb: 1 }}>
                        <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
                    </Grid>
                    <Grid item xs={8} md={3}>
                        {/*<Typography variant="body2" sx={{ pr: { md: 5 } }}>*/}
                        {/*    <b>RISK DISCLOSURE</b> <br/>*/}
                        {/*    Foreign Exchange and CFD trading are high risk and not*/}
                        {/*    suitable for everyone. You should carefully consider your*/}
                        {/*    investment objectives, level of experience and risk appetite*/}
                        {/*    before making a decision to trade.*/}
                        {/*    Most importantly, do not invest money you cannot afford to lose.*/}
                        {/*</Typography>*/}

                        {/*<Stack*/}
                        {/*    direction="column"*/}
                        {/*    justifyContent={{ xs: 'center', md: 'flex-start' }}*/}
                        {/*    sx={{ mt: 5, mb: { xs: 5, md: 0 } }}*/}
                        {/*>*/}
                        {/*    <SocialsButton sx={{ mx: 0.5 }} />*/}
                        {/*</Stack>*/}
                    </Grid>


                </Grid>

                <div style={{ margin:'auto', display:'flex', justifyContent:'center'}} >
                    {LINKS.map((list) =>list.children.map((link) =>
                        <Link
                            to={link.href}
                            key={link.name}
                            color="inherit"
                            variant="body2"
                            component={RouterLink}
                            sx={{ display: 'block', margin:'8px 16px' }}
                        >
                            {link.name}
                        </Link>
                    ))
                    }

                </div>
                <Stack
                    direction="row"
                    justifyContent={{ xs: 'center', md: 'center' }}
                    sx={{ mt: 2, mb: { xs: 2, md: 0 } }}
                >
                    <SocialsButton sx={{ mx: 0.5 }} />
                </Stack>

                <Typography
                    component="p"
                    variant="body2"
                    sx={{
                        mt: 5,
                        pb: 5,
                        fontSize: 13,
                        textAlign: { xs: 'center', md: 'left' },
                    }}
                >
                    © 2021. All rights reserved
                </Typography>
            </Container>
        </RootStyle>
    );
}
