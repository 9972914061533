import _mock from './_mock';
import { randomInArray } from './funcs';

// ----------------------------------------------------------------------

export const _onlinepackage = [
  {
    id: 1,
    name: 'IntelligenceFx Substratum Series',
  },
  {
    id: 2,
    name: 'Traders Strategy Guidebook',
  },
  {
    id: 3,
    name: 'One to One Session (via zoom)',
  },
  {
    id: 4,
    name: 'Weekend, Mid-week and Monthly Live Webinar',
  },
  {
    id: 5,
    name: 'Weekend Market Breakdown',
  },
  {
    id: 6,
    name: 'Mid-week Market Breakdown & Review',
  },
  {
    id: 7,
    name: 'Access to Community',
  },
  {
    id: 8,
    name: 'IntelligenceFx Meet Up',
  },
  {
    id: 9,
    name: 'Certificate',
  },
  {
    id: 10,
    name: 'Lifetime Mentorship',
  },
  {
    id: 11,
    name: 'Access to Recorded and Live Bootcamp Series',
  },
  {
    id: 12,
    name: ' Access to Signal and Setup for 3 Months',
  },
];

export const _physicalpackage = [
  {
    id: 1,
    name: 'IntelligenceFx Substratum Series',
  },
  {
    id: 2,
    name: 'Traders Strategy Guidebook',
  },
  {
    id: 3,
    name: 'One to One Session (physical)',
  },
  {
    id: 4,
    name: 'Weekend, Mid-week and Monthly Live Webinar',
  },
  {
    id: 5,
    name: 'Weekend Market Breakdown',
  },
  {
    id: 6,
    name: 'Mid-week Market Breakdown & Review',
  },
  {
    id: 7,
    name: 'Access to Community',
  },
  {
    id: 8,
    name: 'IntelligenceFx Meet Up',
  },
  {
    id: 9,
    name: 'Certificate',
  },
  {
    id: 10,
    name: 'Lifetime Mentorship',
  },
  {
    id: 11,
    name: 'Access to Recorded and Live Bootcamp Series',
  },
  {
    id: 12,
    name: ' Access to Signal and Setup for 3 Months',
  },
];

export const _bookingsOverview = [...Array(3)].map((_, index) => ({
  status: ['Pending', 'Cancel', 'Done'][index],
  quantity: _mock.number.percent(index) * 1000,
  value: _mock.number.percent(index),
}));

export const _bookingReview = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  description: _mock.text.description(index),
  avatar: _mock.image.avatar(index),
  rating: _mock.number.rating(index),
  postedAt: _mock.time(index),
  tags: ['Great Sevice', 'Recommended', 'Best Price'],
}));

export const _bookingNew = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  avatar: _mock.image.avatar(index),
  bookdAt: _mock.time(index),
  roomNumber: 'A-21',
  roomType: randomInArray(['double', 'king', 'single']),
  person: '3-5',
  cover: `https://minimal-assets-api.vercel.app/assets/images/rooms/room-${index + 1}.jpg`,
}));
