import * as Yup from 'yup';
import {useEffect, useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {Link, Stack, Alert, IconButton, InputAdornment, Typography, Box} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const thePath = window.location.href;
  var url = new URL(thePath);
  var packageId = url.searchParams.get("package");
  var reference = sessionStorage.getItem("reference")??null

  const { login } = useAuth();
  const userDetails = JSON.parse( localStorage.getItem('userDetails'));

  const isMountedRef = useIsMountedRef();
  const navigate =useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [dep, setDep] = useState(1);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: 'proc@gmail.com',
    password: 'prosper',
    remember: false,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data1) => {
    try {
      const {
        data: { code, msg },
      } = await login(data1.email, data1.password);
      setDep(dep+1)
      if (code === 300) {
        setError('afterSubmit', { message: msg });
      }
    } catch (error) {
      console.error(error);
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  useEffect(()=>{
    if(userDetails){
      navigate('/dashboard/app');
    }
  },[dep])

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/*<RHFCheckbox name="remember" label="Remember me" />*/}
        {/*<Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.register2}>*/}
        {/*  Signup for free*/}
        {/*</Link>*/}

        <Typography variant="body2" align="center">
          Forget password? &nbsp;
          <Link variant="subtitle2" component={RouterLink} underline="none"
                to={PATH_AUTH.resetPassword}
          >
             reset here!
          </Link>
        </Typography>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}
